import { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { usePost, useAuth } from 'hooks';
import { signup } from 'services/api';

import {
  FormContainer,
  Logo,
  H2,
  H5,
  Form,
  Button,
  Input,
  Alert,
} from './styled';
import { Checkbox } from 'components/common';

const Signup = () => {
  const [form, setForm] = useState({
    charityName: '',
    userName: '',
    userEmail: '',
    hasAgreed: false,
  });
  const { user } = useAuth();
  const [{ res, loading, error }, postSignup] = usePost({
    url: signup,
  });

  const handleChange = (key) => (value) =>
    setForm((s) => ({ ...s, [key]: value }));

  const handleSubmit = (e) => {
    e.preventDefault();
    postSignup(form);
  };

  if (user) {
    return <Redirect to='/' />;
  }

  const disableSubmit =
    !form.userName ||
    !form.charityName ||
    !form.userEmail ||
    !form.hasAgreed ||
    res ||
    error ||
    loading;

  return (
    <FormContainer>
      <Logo icon='logo' />
      <H2>Rescue Signup</H2>
      {!res && (
        <>
          <H5>
            The Pet Adoption Website is free to use and super simple to set up.
            Just fill in your details below to create your free account.
          </H5>
          <Alert type='error'>
            Please do not use this form if you are a member of the public
            seeking to rehome a pet - use the &apos;Apply to Adopt&apos; button
            on the pet&apos;s profile instead!
          </Alert>
          <Form onSubmit={handleSubmit}>
            <Input
              disabled={loading}
              placeholder='Charity or Shelter Name'
              value={form.charityName}
              onChange={handleChange('charityName')}
            />
            <Input
              disabled={loading}
              placeholder='Contact Name'
              value={form.userName}
              onChange={handleChange('userName')}
            />
            <Input
              disabled={loading}
              placeholder='Contact Email'
              value={form.userEmail}
              onChange={handleChange('userEmail')}
            />
            <Checkbox
              onChange={handleChange('hasAgreed')}
              value={form.hasAgreed}
              label={
                <span>
                  I have read and agree to the PAW terms and conditions found{' '}
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://paw-share.s3.eu-west-1.amazonaws.com/paw-terms-v1.pdf'
                  >
                    here
                  </a>
                </span>
              }
            />
            <Button
              disabled={disableSubmit}
              fullWidth
              size='large'
              onClick={handleSubmit}
            >
              Sign Up
            </Button>
          </Form>
        </>
      )}
      {res && (
        <Alert type='info' style={{ marginTop: 25 }}>
          Thanks for signing up! You should receive a confirmation email
          shortly.
        </Alert>
      )}
      {error && (
        <Alert type='error'>
          Something went wrong, please try again later.
        </Alert>
      )}
    </FormContainer>
  );
};

export default Signup;
